<!--
	@component:	ANZCHECKBOX
	@desc:		Render checkbox.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2020-03-30
 -->
<template>
  <li class="list-main__item">
    <button class="list-main__item-link flex" @click.prevent="onClickItem(data)" :class="{ 'is-disabled': IsLocked === 'Y' || !Show }">
      <el-checkbox v-model="Valid" :disabled="IsLocked === 'Y' || !Show" :name="Xnodekey"></el-checkbox>
      <span :for="Xnodekey" class="m-l-1">
        <span class="badge" v-if="MIQVzModell" :style="{ 'background-color': MIQVzModell }"></span>
        {{ Lanqkey01 }}
      </span>
    </button>

    <!-- Actions -->
    <div v-if="PdfOnPath || InfoTooltipNumber">
      <ListItemActions :data="data"></ListItemActions>
    </div>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
import { get } from 'lodash';
import DebugItem from '@/components/DebugItem';
import ListItemActions from '@/components/List/ListItemActions';
import mixins from '@/shared/mixins/index';

export default {
  name: 'ANZCHECKBOX',

  components: {
    DebugItem,
    ListItemActions
  },

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },

    updateNodeKey: {
      type: String,
      required: true
    }
  },

  computed: {
    InfoTooltipNumber() {
      return get(this.data, 'InfoTooltipNumber');
    },

    IsLocked() {
      return get(this.data, 'IsLocked');
    },

    Lanqkey01() {
      return get(this.data, 'Lanqkey01');
    },

    MIQVzModell() {
      return get(this.data, 'MIQVzModell');
    },

    PdfOnPath() {
      return get(this.data, 'PdfOnPath');
    },

    Show() {
      return get(this.data, 'Show');
    },

    Valid() {
      return get(this.data, 'Valid');
    },

    visibleControl() {
      return this.visibleControls[0].toLowerCase();
    },

    visibleControls() {
      return this.$store.state.visibleControls;
    },

    Xnodekey() {
      return get(this.data, 'Xnodekey');
    }
  },

  data() {
    return {};
  },

  methods: {
    onClickItem(node) {
      const Xreltyp = get(node, 'Xreltyp', '');
      const Xnodekey = get(node, 'Xnodekey', '');

      if (Xreltyp === 'VAL') {
        this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: Xnodekey });

        this.requestNode(Xnodekey).then(() => {
          this.$events.$emit(`${this.visibleControl}:refresh:child`, node);
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
